import React, { useEffect, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Card } from "react-bootstrap";
import LeftImg from "../../../assets/slide/left.svg";
import RightImg from "../../../assets/slide/right.svg";
import liveSVG from "../../../assets/common/live.svg";
import EventSlideList from "../../../data/EventSlidesList.json";
import { getProgramSchedule } from "../../../api/apiClient";
import { STATIC_BASE_URL } from "../../../config";

const Slide = () => {
  const [slideList, setSlideList] = useState(EventSlideList);
  const [currentDateIndex, setCurrentDateIndex] = useState(0);
  const [visibleStartIndex, setVisibleStartIndex] = useState(0);

  console.log(visibleStartIndex);

  const getSlideList = () => {
    getProgramSchedule()
      .then((res) => {
        console.log("slideList res : ", res.data);
        setSlideList(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getSlideList();
  }, []);

  const handlePrevSlide = () => {
    if (visibleStartIndex > 0) {
      setVisibleStartIndex((prev) => prev - 1);
    } else if (currentDateIndex > 0) {
      setCurrentDateIndex((prev) => prev - 1);
      const prevSlide = slideList.slides[currentDateIndex - 1];
      setVisibleStartIndex(prevSlide.events.length - 3);
    }
  };

  const handleNextSlide = () => {
    const currentSlide = slideList.slides[currentDateIndex];
    if (visibleStartIndex + 3 < currentSlide.events.length) {
      setVisibleStartIndex((prev) => prev + 1);
    } else if (currentDateIndex < slideList.slides.length - 1) {
      setCurrentDateIndex((prev) => prev + 1);
      setVisibleStartIndex(0);
    }
  };

  if (!slideList || !slideList.slides || slideList.slides.length === 0) {
    // return <div>Loading...</div>;
    return <div></div>;
  }

  const currentSlide = slideList.slides[currentDateIndex];

  return (
    <Row
      className={"footer"}
      style={{
        height: "17vh",
        maxHeight: "18vh",
        position: "absolute",
        bottom: "auto",
        left: "50%",
        transform: "translateX(-50%)",
        zIndex: 1000,
        paddingRight: "2rem",
        paddingLeft: "3rem",
      }}
    >
      <Col xs={12} className={"w-100 h-100"}>
        <Card
          className={"display border-0 rounded-5 h-100"}
          style={{
            backgroundColor: "#2C2C2C",
          }}
        >
          <Row className={"justify-content-center h-100"}>
            {/* 왼쪽 arr */}
            <Col
              className={"text-center my-auto"}
              style={{
                flex: "0 0 auto",
                width: "10%",
              }}
            >
              <img
                src={LeftImg}
                alt={"slide"}
                className={"w-75 cursor-hover"}
                tabIndex={0}
                onClick={handlePrevSlide}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handlePrevSlide();
                  }
                }}
                style={{
                  visibility: currentDateIndex > 0 ? "visible" : "hidden",
                }}
              />
            </Col>
            {/* 날짜 */}
            <Col
              className={
                "d-flex flex-column justify-content-center align-items-center text-center my-auto border-end custom-border-secondary me-4"
              }
              style={{
                flex: "0 0 auto",
                width: "12%",
                height: "80%",
              }}
            >
              <h3 className={"fw-normal mb-2"}>{currentSlide.date}</h3>
              <h3 className={"fw-normal fs-2"}>{currentSlide.day}</h3>
            </Col>
            {/* 슬라이드 */}
            <Col
              className="my-auto"
              style={{
                overflow: "hidden",
                width: "66%",
              }}
            >
              <Row
                className="justify-content-between align-items-center"
                style={{
                  display: "grid",
                  gridTemplateColumns: `repeat(${Math.max(
                    currentSlide.events.length,
                    3
                  )}, 33.33%) auto auto`,
                  transition: "transform 0.5s ease",
                  transform: `translateX(-${visibleStartIndex * 33.33}%)`,
                }}
              >
                {currentSlide.events.map((event, idx) => (
                  <Col
                    key={idx}
                    className="my-auto"
                    style={{
                      width: "100%",
                      height: "100%",
                      boxSizing: "border-box",
                    }}
                  >
                    <Card
                      className={"border-0 rounded-5 overflow-hidden"}
                      style={{
                        maxHeight: "120px",
                        width: "100%",
                        backgroundColor: "#000",
                      }}
                    >
                      {/* {event.isLive && (
                        <div
                          style={{
                            maxHeight: "120px",
                            position: "absolute",
                            bottom: "40px",
                            right: "-15px",
                          }}
                        >
                          <img
                            src={liveSVG}
                            alt={"LIVE"}
                            className={"mx-2 align-middle my-auto"}
                            style={{ width: "390px" }}
                          />
                        </div>
                      )} */}
                      {/* {event.isLive && (
                        <div
                          style={{
                            // maxHeight: "120px",
                            position: "absolute",
                            top: "10px",
                            left: "5px",
                          }}
                        >
                          <img
                            src={liveSVG}
                            alt={"LIVE"}
                            className={"mx-2 align-middle my-auto"}
                            style={{ minWidth: "3rem" }}
                          />
                        </div>
                      )} */}

                      <div style={{ height: "120px" }}>
                        <img
                          src={
                            event.image
                              ? `${STATIC_BASE_URL}${event.image}`
                              : "https://via.placeholder.com/150"
                          }
                          // alt={event.title}
                          alt={""}
                          className={"img-fluid w-100 h-100"}
                          style={{
                            objectFit: "cover",
                            objectPosition: "start start",
                          }}
                        />
                      </div>
                    </Card>
                    <div
                      className={"mt-1"}
                      style={{
                        maxWidth: "100%",
                        overflow: "hidden",
                        height: "2.5rem",
                      }}
                    >
                      <span
                        className={"fs-3 align-middle my-auto me-2 fw-bold"}
                      >
                        {event.time}
                      </span>
                      {/* {event.isLive && (
                        <img
                          src={liveSVG}
                          alt={"LIVE"}
                          className={"mx-2 align-middle my-auto"}
                          style={{ minWidth: "3rem" }}
                        />
                      )} */}
                      <span className={"fs-3 align-middle my-auto text-white"}>
                        {event.title}
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            {/* 오른쪽 arr */}
            <Col
              className={"text-center my-auto"}
              style={{
                flex: "0 0 auto",
                width: "10%",
              }}
            >
              <img
                src={RightImg}
                alt={"slide"}
                className={"w-75 cursor-hover"}
                tabIndex={0}
                onClick={handleNextSlide}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleNextSlide();
                  }
                }}
                style={{
                  visibility:
                    currentDateIndex < slideList.slides.length - 1
                      ? "visible"
                      : "hidden",
                }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

export default Slide;
