import React, {useEffect, useState, useRef, useLayoutEffect} from "react";
import {Button, Row, Col} from "react-bootstrap";
import SeniorCenterDataListJSON from "../../../data/SeniorCenterDataList.json";
import Before from "../../../assets/broadcast/before.svg";
import AllSvg from "../../../assets/video/icon-all.svg";
import AllActiveSvg from "../../../assets/video/icon-all_active.svg";
import BlurPng from "../../../assets/common/blurImg.png";
import "./VideoCall.scss";
import Call_screen from "../conf/call_screen";
import KeyboardDebugger from "../../../hooks/KeyboardDebugger";
import ForceNavigationOnClick from "../../../hooks/ForceNavigationOnClick";
import {getJWT} from "../../../api/apiClient";
import SeniorCenter from "./SeniorCenter";

const VideoCall = ({isFullscreen, centerInfo}) => {

  const [token, setToken] = useState("");

  useEffect(() => {
    if (Object.keys(centerInfo).length !== 0) {
      // console.log(centerInfo);
      getJWT(centerInfo.name)
        .then((res) => {
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [centerInfo]);

  const keyboard = KeyboardDebugger();
  // const click = ForceNavigationOnClick();

  const [activeMenu, setActiveMenu] = useState("boroughSelection");
  const [boroughList, setBoroughList] = useState(SeniorCenterDataListJSON.boroughs);
  const [activeBorough, setActiveBorough] = useState("all");


  useEffect(() => {
    console.log("isFullscreen: " + isFullscreen);
  }, [isFullscreen]);


  /*useEffect(() => {
    console.log("token: ", token);
  }, [token]);*/


  /*const onHandleFocus = (e) => {
    const focusedElement = e.target;
    const dataId = focusedElement.getAttribute('data-id');
    console.log("Focused Element:", focusedElement);
    console.log("Data ID:", dataId);
    lastFocusedElementRef.current = focusedElement;
  };*/

  /*useEffect(() => {
    console.log("lastFocusedElementRef", lastFocusedElementRef);
  }, [lastFocusedElementRef]);*/

  /*const renderContent = (activeMenu) => {
    if (activeMenu === "boroughSelection") {
      // return <BoroughSelection/>;
      return (
        <>
          {token && <BoroughSelection/>}
        </>
      );
    } else if (activeMenu === "seniorCenter") {
      // return <SeniorCenter/>;
      return (
        <>
          {token && <SeniorCenter/>}
        </>
      );
    } else if (activeMenu === "communityCenterVideoCall") {
      // return <CommunityCenterVideoCall token={token}/>;
      return (
        <>
          {token && <CommunityCenterVideoCall token={token}/>}
        </>
      );
    }
  };*/

  const handleBoroughSelection = (borough) => {
    setActiveMenu("seniorCenter");
    setActiveBorough(borough);
  }

  const BoroughSelection = () => {
    return (
      <>
        {boroughList.map((borough, idx) => (
          <Col xs={4} style={{height: "16rem"}} key={idx}>
            <Button
              tabIndex={isFullscreen ? 0 : -1}
              // tabIndex={0}
              className={`h-100 w-100 center-btn`}
              style={{
                backgroundColor: borough.name === activeBorough ? "#D66440" : "black",
                border: "none",
                cursor: "pointer",
              }}
              // disabled={!isFullscreen}
              onClick={() => handleBoroughSelection(borough.name)}
              onKeyDown={(e) => {
                // e.preventDefault();
                if (e.key === "Enter" || e.key === " ") {
                  // setActiveMenu("seniorCenter");
                  // setActiveBorough(borough.name);
                  handleBoroughSelection(borough.name);
                }
              }}
              // onFocus={onHandleFocus}
            >
              {borough.name_kor === "전체보기" ? (
                <div className="d-flex justify-content-center gap-3">
                  <span className={"my-auto text-center fs-1"}>{borough.name_kor}</span>
                  <img src={borough.name === activeBorough ? AllActiveSvg : AllSvg} alt="all-icon"/>
                </div>
              ) : (
                <span className={"my-auto text-center fs-1"}>{borough.name_kor}</span>
              )}
            </Button>
          </Col>
        ))}
      </>
    );
  };

  /*const SeniorCenter = () => {
    let centers = [];
    if (activeBorough === "all") {
      centers = boroughList.flatMap((borough) => borough.centers);
    } else {
      const borough = boroughList.find((b) => b.name === activeBorough);
      centers = borough ? borough.centers : [];
    }

    const selectSeniorCenter = (center, e) => {
      scrollPositionRef.current = scrollContainerRef.current.scrollTop;
      setSelectSeniorCenterList([
        ...selectSeniorCenterList.filter((c) => c.name !== center.name),
        center,
      ]);
      lastFocusedElementRef.current = e.currentTarget;
    };

    const deleteSeniorCenter = (center, e) => {
      scrollPositionRef.current = scrollContainerRef.current.scrollTop;
      setSelectSeniorCenterList(selectSeniorCenterList.filter((c) => c.name !== center.name));
      lastFocusedElementRef.current = e.currentTarget;
    };

    const selectedNames = selectSeniorCenterList.map((item) => item.name_kor).join(", ");

    const [selectedCenterText, setSelectedCenterText] = useState("수신 경로당명");

    const connectCall = () => {
      console.log("전화걸기");
      if (selectSeniorCenterList.length === 0) {
        setSelectedCenterText("수신 경로당을 선택해주세요.");
        return;
      }
      setActiveMenu("communityCenterVideoCall");
    };

    return (
      <Row className="py-3 px-5" style={{height: "100%", overflow: "hidden"}}>
        <Row className="fs-3 fw-bold py-3 border-bottom custom-border-secondary">
          <Col xs={9} className="border-end custom-border-secondary my-auto align-middle">
            <span>{selectSeniorCenterList.length === 0 ? selectedCenterText : selectedNames}</span>
          </Col>
          <Col xs={3} className="text-center my-auto align-middle">
            <Button
              tabIndex={0}
              variant="primary"
              className={"ms-4 px-5"}
              onClick={(e) => {
                e.preventDefault();
                connectCall();
              }}
              onKeyDown={(e) => {
                e.preventDefault();
                if (e.key === "Enter") {
                  connectCall();
                }
              }}
              onFocus={onHandleFocus}
            >
              전화걸기
            </Button>
          </Col>
        </Row>

        <div
          ref={scrollContainerRef}
          style={{
            maxHeight: "calc(100% - 5rem)",
            overflowY: "auto",
            zIndex: 1,
          }}
        >
          {centers.map((center, idx) => {
              const isSelected = selectSeniorCenterList.some((c) => c.name === center.name);
              const borough = boroughList.find((b) => b.centers.some((c) => c.name === center.name));
              const boroughNameKor = borough ? borough.name_kor : "";
              return (
                <Row key={idx}
                     className="fs-3 text-center align-items-center py-4 border-bottom custom-border-secondary">
                  <Col xs={1}>{boroughNameKor}</Col>
                  <Col xs={8}>{center.name_kor}</Col>
                  <Col xs={3}>
                    <Button
                      data-id={idx}
                      type={"button"}
                      className={`px-5 btn ${isSelected ? "btn-outline-primary" : "btn-primary"}`}
                      tabIndex={0}
                      onClick={(e) => {
                        isSelected ? deleteSeniorCenter(center, e) : selectSeniorCenter(center, e);
                        e.target.focus();
                      }}
                      onFocus={onHandleFocus}
                    >
                      {isSelected ? "선택해제" : "선택하기"}
                    </Button>
                  </Col>
                </Row>
              );
            })}
          {centers.length === 0 && <div className={"fs-3 text-center py-4"}>해당 구에 등록된 경로당이 없습니다.</div>}
        </div>

        <div className={"scale"}>
          <img
            src={Before}
            alt="before"
            className={`position-absolute return-icon z-3 end-0 bottom-0 cursor-hover ${isFullscreen ? "full" : ""}`}
            tabIndex={0}
            onClick={backToBoroughSelection}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                backToBoroughSelection();
              }
            }}
            onFocus={onHandleFocus}
          />
        </div>
        {isFullscreen && (
          <img
            src={BlurPng}
            alt="blur-img"
            style={{
              width: "36vh",
              height: "36vh",
              position: "absolute",
              bottom: "-3vh",
              right: "10vh",
              zIndex: 0,
            }}
          />
        )}
      </Row>
    );
  };*/

  const CommunityCenterVideoCall = () => {
    return <Call_screen token={token}></Call_screen>;
    // return <Call_screen></Call_screen>;
  };

  return (
    <Row className={"p-4 row-gap-4 overflow-y-auto "}>
      {/*{renderContent(activeMenu)}*/}
      {token && activeMenu === "boroughSelection" && boroughList.map((borough, idx) => (
        <Col xs={4} style={{height: "16rem"}} key={idx}>
          <Button
            tabIndex={isFullscreen ? 0 : -1}
            // tabIndex={0}
            className={`h-100 w-100 center-btn`}
            style={{
              backgroundColor: borough.name === activeBorough ? "#D66440" : "black",
              border: "none",
              cursor: "pointer",
            }}
            // disabled={!isFullscreen}
            onClick={() => handleBoroughSelection(borough.name)}
            onKeyDown={(e) => {
              // e.preventDefault();
              if (e.key === "Enter" || e.key === " ") {
                // setActiveMenu("seniorCenter");
                // setActiveBorough(borough.name);
                handleBoroughSelection(borough.name);
              }
            }}
            // onFocus={onHandleFocus}
          >
            {borough.name_kor === "전체보기" ? (
              <div className="d-flex justify-content-center gap-3">
                <span className={"my-auto text-center fs-1"}>{borough.name_kor}</span>
                <img src={borough.name === activeBorough ? AllActiveSvg : AllSvg} alt="all-icon"/>
              </div>
            ) : (
              <span className={"my-auto text-center fs-1"}>{borough.name_kor}</span>
            )}
          </Button>
        </Col>
      ))}
      {token && activeMenu === "seniorCenter" &&
        <SeniorCenter
          activeBorough={activeBorough}
          boroughList={boroughList}
          activeMenu={activeMenu}
          setActiveMenu={setActiveMenu}
          isFullscreen={isFullscreen}
          token={token}
        />
      }
      {token && activeMenu === "communityCenterVideoCall" && <Call_screen token={token}></Call_screen>}

    </Row>
  );
};

export default VideoCall;