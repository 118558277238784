import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {Button, Col, Row} from "react-bootstrap";
import Before from "../../../assets/broadcast/before.svg";
import BlurPng from "../../../assets/common/blurImg.png";

const SeniorCenter = ({
                        activeBorough,
                        boroughList,
                        activeMenu,
                        setActiveMenu,
                        isFullscreen,
  token
                      }) => {
  const [selectSeniorCenterList, setSelectSeniorCenterList] = useState([]);

  useEffect(() => {
    setSelectSeniorCenterList([]);
  }, [activeBorough]);

  const scrollContainerRef = useRef(null);
  const scrollPositionRef = useRef(0);
  const lastFocusedElementRef = useRef(null);

  const onHandleFocus = (e) => {
    const focusedElement = e.target;
    const dataId = focusedElement.getAttribute('data-id');
    console.log("Focused Element:", focusedElement);
    console.log("Data ID:", dataId);
    lastFocusedElementRef.current = focusedElement;
  };

  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollPositionRef.current;
    }
  });

  useLayoutEffect(() => {
    if (lastFocusedElementRef.current) {
      setTimeout(() => {
        lastFocusedElementRef.current.focus();
      }, 0);
    }
  }, [activeMenu]);

  let centers = [];
  if (activeBorough === "all") {
    centers = boroughList.flatMap((borough) => borough.centers);
  } else {
    const borough = boroughList.find((b) => b.name === activeBorough);
    centers = borough ? borough.centers : [];
  }

  const selectSeniorCenter = (center, e) => {
    scrollPositionRef.current = scrollContainerRef.current.scrollTop;
    setSelectSeniorCenterList([
      ...selectSeniorCenterList.filter((c) => c.name !== center.name),
      center,
    ]);
    lastFocusedElementRef.current = e.currentTarget;
  };

  const deleteSeniorCenter = (center, e) => {
    scrollPositionRef.current = scrollContainerRef.current.scrollTop;
    setSelectSeniorCenterList(selectSeniorCenterList.filter((c) => c.name !== center.name));
    lastFocusedElementRef.current = e.currentTarget;
  };

  const selectedNames = selectSeniorCenterList.map((item) => item.name_kor).join(", ");

  const [selectedCenterText, setSelectedCenterText] = useState("수신 경로당명");

  const connectCall = () => {
    console.log("전화걸기");
    if (selectSeniorCenterList.length === 0) {
      setSelectedCenterText("수신 경로당을 선택해주세요.");
      return;
    }
    setActiveMenu("communityCenterVideoCall");
    // token && activeMenu === "communityCenterVideoCall" && <CommunityCenterVideoCall/>
  };

  const backToBoroughSelection = () => {
    setActiveMenu("boroughSelection");
  };

  return (
    <Row className="py-3 px-5" style={{height: "100%", overflow: "hidden"}}>
      <Row className="fs-3 fw-bold py-3 border-bottom custom-border-secondary">
        <Col xs={9} className="border-end custom-border-secondary my-auto align-middle">
          <span>{selectSeniorCenterList.length === 0 ? selectedCenterText : selectedNames}</span>
        </Col>
        <Col xs={3} className="text-center my-auto align-middle">
          <Button
            tabIndex={0}
            variant="primary"
            className={"ms-4 px-5"}
            onClick={(e) => {
              e.preventDefault();
              connectCall();
            }}
            onKeyDown={(e) => {
              e.preventDefault();
              if (e.key === "Enter") {
                connectCall();
              }
            }}
            onFocus={onHandleFocus}
          >
            전화걸기
          </Button>
        </Col>
      </Row>

      <div
        ref={scrollContainerRef}
        style={{
          maxHeight: "calc(100% - 5rem)",
          overflowY: "auto",
          zIndex: 1,
        }}
      >
        {centers.map((center, idx) => {
          const isSelected = selectSeniorCenterList.some((c) => c.name === center.name);
          const borough = boroughList.find((b) => b.centers.some((c) => c.name === center.name));
          const boroughNameKor = borough ? borough.name_kor : "";
          return (
            <Row key={idx}
                 className="fs-3 text-center align-items-center py-4 border-bottom custom-border-secondary">
              <Col xs={1}>{boroughNameKor}</Col>
              <Col xs={8}>{center.name_kor}</Col>
              <Col xs={3}>
                <Button
                  data-id={idx}
                  type={"button"}
                  className={`px-5 btn ${isSelected ? "btn-outline-primary" : "btn-primary"}`}
                  tabIndex={0}
                  onClick={(e) => {
                    isSelected ? deleteSeniorCenter(center, e) : selectSeniorCenter(center, e);
                    e.target.focus();
                  }}
                  onFocus={onHandleFocus}
                >
                  {isSelected ? "선택해제" : "선택하기"}
                </Button>
              </Col>
            </Row>
          );
        })}
        {centers.length === 0 && <div className={"fs-3 text-center py-4"}>해당 구에 등록된 경로당이 없습니다.</div>}
      </div>

      <div className={"scale"}>
        <img
          src={Before}
          alt="before"
          className={`position-absolute return-icon z-3 end-0 bottom-0 cursor-hover ${isFullscreen ? "full" : ""}`}
          tabIndex={0}
          onClick={backToBoroughSelection}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              backToBoroughSelection();
            }
          }}
          onFocus={onHandleFocus}
        />
      </div>
      {isFullscreen && (
        <img
          src={BlurPng}
          alt="blur-img"
          style={{
            width: "36vh",
            height: "36vh",
            position: "absolute",
            bottom: "-3vh",
            right: "10vh",
            zIndex: 0,
          }}
        />
      )}
    </Row>
  );
};

export default SeniorCenter;