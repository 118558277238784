import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { JaaSMeeting, JitsiMeeting } from '@jitsi/react-sdk';
import { getJWT } from '../../../api/apiClient';

const handleJaaSIFrameRef = iframeRef => {
    iframeRef.style.border = '0px solid #3d3d3d';
    iframeRef.style.background = '#3d3d3d';
    iframeRef.style.height = '1080px';
    //iframeRef.style.marginBottom = '20px';
};



// ISC App ID
var YOUR_APP_ID = "vpaas-magic-cookie-0a2b3317f63846fd92f5d7b0d281aadd"
//var YOUR_VALID_JWT = "eyJhbGciOiJSUzI1NiIsImtpZCI6InZwYWFzLW1hZ2ljLWNvb2tpZS1hODhhM2YyNmZhZjE0ZmE2OTc5ZDBkOGFiMWY4MDQ3NS9lMzQzMDUiLCJ0eXAiOiJKV1QifQ.eyJleHAiOjE3MzM4NTUyMzgsIm5iZlRpbWUiOjE3MzM4NDgwMjgsInJvb20iOiIqIiwic3ViIjoidnBhYXMtbWFnaWMtY29va2llLWE4OGEzZjI2ZmFmMTRmYTY5NzlkMGQ4YWIxZjgwNDc1IiwiY29udGV4dCI6eyJ1c2VyIjp7Im1vZGVyYXRvciI6InRydWUiLCJpZCI6ImI1ZjBiNjVjLTA0MWQtNDliOS1hNjgxLTgwMzRlNDVjNGEwNCIsIm5hbWUiOiLsg6TtjqDrk5zrr7jslZkifSwiZmVhdHVyZXMiOnsibGl2ZXN0cmVhbWluZyI6InRydWUiLCJyZWNvcmRpbmciOiJ0cnVlIiwib3V0Ym91bmQtY2FsbCI6InRydWUiLCJzaXAtb3V0Ym91bmQtY2FsbCI6InRydWUiLCJ0cmFuc2NyaXB0aW9uIjoidHJ1ZSJ9fSwiaXNzIjoiY2hhdCIsImF1ZCI6ImppdHNpIn0.Hu_DYqghqyUIaoF74OKqvlOGND3tnpjmaWwJAdm216XeQLuV84oxGM7ytcwLIOTKgivYXmikgPAE1Wr6heFvHk4Eda9TnnBnhvy4vEre2uRfrHEOD1WNCgfY1R6xJgqtivoZR7X5Pa3bnWIC-yWa4IKrbU00NPTBw1g-tvpSDZsLlFwvTo9gd7Q4viXvb7qoryx-_IWJh6papcWohOpYIpjQ6WPG3fnXWA8vnr4EYLjxyHBsO0MElwrAH85yfdD_7QuSfShqdl4TcnZqGltBFI9ONHaLFR8ByrnNoDp94amGf8GVKQ3a2PLl80zOxs1mKcF7AiQHHye6axfeSA1rkg"

function Conf_screen({token}) {
    //const { token } = props

    // const [jwt, setJwt] = useState('')

    // useEffect(() => {
    //     getJWT("샤펠드미앙").then((res) => {
    //         console.log("token res : ", res.data.token.slice(2, -1));
    //         setJwt(res.data.token.slice(2, -1));
    //     })
    //     .catch((error) => {
    //         console.error(error);
    //       });
    // }, [])
    useEffect(() => {
        console.log("token: ", token);
    }, [token])

    return (
        <JaaSMeeting
            appId = { YOUR_APP_ID }
            //roomName={generateRoomName()}
            roomName={"ICSC-인천-실시간방송"}
            jwt = { token }
            configOverwrite = {{
                startWithAudioMuted: true,
            //     disableModeratorIndicator: true,
            //     startScreenSharing: false,
            //     enableEmailInStats: false,
            //     welcomePage: {
            //         // Whether to disable welcome page. In case it's disabled a random room
            //         // will be joined when no room is specified.
            //         disabled: true,
            //         // If set, landing page will redirect to this URL.
            //         customUrl: ''
            //     },                

            // lobby: {
            //     // If Lobby is enabled, it starts knocking automatically. Replaces `autoKnockLobby`.
            //     autoKnock: false,
            //     // Enables the lobby chat. Replaces `enableLobbyChat`.
            //     enableChat: true,
            //     },                
            // }}

            // interfaceConfigOverwrite = {{
            //     VIDEO_LAYOUT_FIT: 'nocrop',
            //     MOBILE_APP_PROMO: false,
            //     TILE_VIEW_MAX_COLUMNS: 4
            prejoinPageEnabled: false,

            }}
           
            // devices
            //userInfo = {{
            //    displayName: 'INCHOEN_TESTUSER'
            //}}
            
            //spinner = {}
            
            // Update this with the `8x8.vc` or `stage.8x8.vc` version of interest
            // and avoid mixing up different domains and release versions
            // on the same page at the same time, as only the first
            // external api script will be loaded.
            // release = 'release-1234'

            //useStaging={false}
            getIFrameRef={handleJaaSIFrameRef} 
            />
    )
}

Conf_screen.propTypes = {

}

export default Conf_screen
