import React from 'react'
import Webcam from "react-webcam";

function Capture() {

    // Webcam 장비 강제 인식으로 Captureboard 연결할 예정
//    const [deviceId, setDeviceId] = React.useState({});
    const [allDevices, setAllDevices] = React.useState([]);
    const [devices, setDevices] = React.useState([]);
    const [aDevice, setADevice] = React.useState();  
    const [vDevice, setVDevice] = React.useState();  
    
    const handleDevices = React.useCallback(
      mediaDevices => {
        // captureboard 이름으로 필터링
        //setDevices(mediaDevices.filter(({ label }) => label.includes("Live Gamer EXTREME 3 (")));
        setDevices(mediaDevices.filter(({ label }) => label.includes("Live Streamer CAP 4K")));
        setAllDevices(mediaDevices);
        console.log(mediaDevices);
      },
      [setDevices]
    );
  
    React.useEffect(
      () => {
        navigator.mediaDevices.enumerateDevices().then(handleDevices);
      },
      [handleDevices]
    );

    React.useEffect(
        () => {
            console.log(devices)
            // Filtering logic
            devices.forEach(device => {
              if (device.kind === "audioinput") {
                setADevice(device);
              } else if (device.kind === "videoinput") {
                setVDevice(device);
              }
            });
        },
        [devices]
    )

    return (
        <>
        {
            devices.length !== 2 && devices.length !== 3 ? 
            <div>
              <br/>
              <h3>오류</h3>
              <ul>
              <li>"장치가 정상적으로 인식되지 않았습니다." </li>
              </ul>
              <h3>장비정보</h3>
              <ul>
              <li>비디오 장치: {`Kind: ${vDevice?.kind}, Label: ${vDevice?.label}, Device ID: ${vDevice?.deviceId}`} </li>
              <li>오디오 장치: {`Kind: ${aDevice?.kind}, Label: ${aDevice?.label}, Device ID: ${aDevice?.deviceId}`} </li>
              </ul>
              <h3>Device List</h3>
              <ul>
                {allDevices.map((device, index) => (
                  <li key={index}>
                    {`Kind: ${device.kind}, Label: ${device.label}, Device ID: ${device.deviceId}`}
                  </li>
                ))}
              </ul>
            </div> :
            <Webcam audio={true} videoConstraints={vDevice} audioConstraints={aDevice}></Webcam>
        }
        </>  
    )
}

export default Capture
