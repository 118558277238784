// src/hooks/useQuery.jsx
import {useLocation} from 'react-router-dom';

const useQuery = () => {
  const location = useLocation();
  // return new URLSearchParams(location.search);

  const queryParams = new URLSearchParams(location.search);

  const query = {};
  for (let param of queryParams.entries()) {
    query[param[0]] = param[1];
  }

  return query;
};

export default useQuery;