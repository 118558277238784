import React, {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import SelectedMessage from "./SelectedMessage";
import BlurPng from "../../../assets/common/blurImg.png";
import {messageList} from "../../../api/apiClient";

const MessageList = ({
                       isFullscreen,
                       selectedMessage,
                       messagesList,
                       handleMessageSelect,
                       centerInfo
                     }) => {
  const [messages, setMessages] = useState();
  // const [selected, setSelected] = useState(null);

  // 메세지 get
  const getMessageList = () => {
    // messageList("SC-001")
    messageList(centerInfo.center_id)
      .then((res) => {
        console.log("messageList res : ", res.data);
        setMessages(res.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getMessageList();
  }, []);

  // 선택된 메시지 찾기
  // const selectedMessage01 = messages?.find(
  //   (message) => message.id === setSelected
  // );

  // 메시지 상세 페이지
  if (selectedMessage) {
    return <SelectedMessage selectedMessage={selectedMessage}/>;
  }

  // 메시지 리스트
  return (
    <Row
      className="py-3 px-5"
      style={{
        // height: "100%",
        overflow: "hidden",
      }}
    >
      {/* 헤더 */}
      <Row
        className="fs-3 fw-bold text-center py-3 border-bottom custom-border-secondary zIndex-1"
        style={{maxHeight: "5rem"}}
      >
        <Col xs={1} className="border-end custom-border-secondary">
          순번
        </Col>
        <Col xs={2} className="border-end custom-border-secondary">
          어르신 성함
        </Col>
        <Col xs={2} className="border-end custom-border-secondary">
          발신자 명
        </Col>
        <Col xs={2} className="border-end custom-border-secondary">
          수신일시
        </Col>
        <Col xs={5} className="text-start ps-4">
          메세지 제목
        </Col>
      </Row>

      {/* 메시지 목록 */}
      <div
        style={{
          maxHeight: "calc(100% - 5rem)",
          overflowY: isFullscreen ? "auto" : "scroll", // 풀스크린일 때만 스크롤 활성화
          scrollbarWidth: isFullscreen ? "auto" : "none",
          zIndex: 1,
        }}
      >
        {messages &&
          messages.map((message) => (
            <Row
              key={message.id}
              className="fs-2 text-center align-items-center py-4 border-bottom custom-border-secondary"
            >
              <Col xs={1}>{message.id}</Col>
              <Col xs={2}>{message.senior_name}</Col>
              <Col xs={2}>{message.author}</Col>
              {/* <Col xs={2}>{message.date.split(" ")[0].slice(5)}</Col> */}
              <Col xs={2}>
                {new Date(message.updated_at)
                  .toLocaleDateString("ko-KR", {
                    month: "2-digit",
                    day: "2-digit",
                  })
                  .replace(/\.$/, "")}
              </Col>
              <Col
                xs={5}
                tabIndex={isFullscreen ? 0 : -1}
                className="text-start ps-4 text-truncate"
                onClick={() => {
                  if (isFullscreen) {
                    handleMessageSelect(message);
                    // setSelected(message.id);
                  }
                }}
                onKeyDown={(event) => {
                  if (event.key === "Enter" && isFullscreen) {
                    handleMessageSelect(message);
                    // setSelected(message.id);
                  }
                }}
                style={{cursor: isFullscreen ? "pointer" : ""}}
              >
                {message.title}
              </Col>
            </Row>
          ))}

        {messages && messages.length === 0 && (
          <Row className="fs-2 text-center py-4 border-bottom custom-border-secondary">
            <Col>메시지가 없습니다.</Col>
          </Row>
        )}
      </div>
      <img
        src={BlurPng}
        alt="blur-img"
        style={{
          width: "36vh",
          height: "36vh",
          position: "absolute",
          bottom: "-3vh",
          right: "10vh",
          zIndex: 0,
        }}
      />
    </Row>
  );
};

export default MessageList;
