import {SWAGGER_BASE_URL} from "../config";
import axios from "axios";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";
// axios.defaults.withCredentials = true;

const axiosClient = axios.create({
  baseURL: SWAGGER_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    // 'Access-Control-Allow-Origin': '*', // CORS 허용
    // 'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS' // CORS 허용
  },
});

export const axiosClientFormData = axios.create({
  baseURL: `${SWAGGER_BASE_URL}`,
  timeout: 10000,
});

const axiosClientPostFormData = (url, formData) => {
  let ret;
  ret = axiosClientFormData.post(url, formData, {
    headers: {
      // "Content-Type": "multipart/form-data",
      "Content-Type": "application/json",
      // "Authorization": `Bearer ${token}`,
    },
  });
  return ret;
};

// deviceID로 경로당 정보 가져오기
export const getCenterInfo = (deviceID) => {
  try {
    return axiosClient.get(`/center-info/?settop_sn=${deviceID}`);
  } catch (e) {
    console.error(e);
  }
};

// 메인 - 하단 공지사항 알림
// export const getNotice = (latest = true, id) => {
export const getNotice = (id) => {
  try {
    return axiosClient.get(`/notice/latest/?center_code=${id}`);
  } catch (e) {
    console.error(e);
  }
};

// 메인 - 하단 메시지 수신 알림
export const getMessage = (latest = true, center_code) => {
  console.log("center_code", center_code);
  if (center_code) {
    try {
      return axiosClient.get(
        `/message/?latest=${latest}&center_code=${center_code}`
      );
    } catch (e) {
      console.error(e);
    }
  }
};

export const messageList = (center_code) => {
  try {
    return axiosClient.get(`/message/?center_code=${center_code}`);
  } catch (e) {
    console.error(e);
  }
};

export const getJWT = (user) => {
  if (user) {
    try {
      return axiosClient.post(`jitsi/token/`, {
        user: user,
      });
    } catch (e) {
      console.error(e);
    }
  }
};

// 양방향 화상 시스템 기능 사용 기록 전달
export const getRecordTvs = (center_code, feature_code, duration, metadata) => {
  try {
    return axiosClient.get(
      `/statistics/record/tvs/?center_code=${center_code}&feature_code=${feature_code}&duration=${duration}&metadata=${metadata}`
    );
  } catch (e) {
    console.error(e);
  }
};

// 경로당 ID 검증
export const getCenterId = (centerId) => {
  try {
    return axiosClient.get(`/verify/center/?center_id=${centerId}`);
  } catch (e) {
    console.error(e);
  }
};

// 버스도착 정보 조회
export const getBusArrival = (centerId) => {
  try {
    return axiosClient.get(`/tvs/bus-arrival/?center_id=${centerId}`);
  } catch (e) {
    console.error(e);
  }
};

// 편성표 조회
export const getProgramSchedule = () => {
  try {
    return axiosClient.get(`/tvs/schedule/client/`);
  } catch (e) {
    console.error(e);
  }
};

// 긴급 방송 조회
export const getEmergencyBroadcast = () => {
  try {
    return axiosClient.get(`/jitsi/content/`);
  } catch (e) {
    console.error(e);
  }
};

// 날씨 정보 상태 조회 (on/off)
export const getWeatherStatus = () => {
  try {
    return axiosClient.get("/tvs/features/?feature=weather");
  } catch (e) {
    console.error(e);
  }
};

// 버스 정보 상태 조회 (on/off)
export const getBusStatus = () => {
  try {
    return axiosClient.get("/tvs/features/?feature=bus");
  } catch (e) {
    console.error(e);
  }
};


// 현재 진행중인 방송 조회
export const getLiveStreaming = () => {
  try {
    return axiosClient.get("/tvs/on-air/");
  } catch (e) {
    console.error(e);
  }
};


// 경로당 기기 정보 등록
export const postCenterInfo = (data) => {
  try {
    return axiosClient.post("/center-info/", data);
  } catch (e) {
    console.error(e);
  }
};