import React from "react";
import styles from "./Highlights.module.css";
import CardDiv from "./CardDiv";
import {MdAir} from "react-icons/md";
import {FiSunrise, FiSunset} from "react-icons/fi";
import {WiHumidity, WiThermometer} from "react-icons/wi";
import {LiaTachometerAltSolid} from "react-icons/lia";
import {MdOutlineVisibility} from "react-icons/md";
import {getTime} from "../../../../util/util";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Highlights({airPollution, currentWeather, isFullscreen}) {
  const {
    main: {aqi},
    components: {pm2_5, so2, no2, o3},
  } = airPollution.list[0];

  const {
    main: {humidity, pressure, feels_like},
    sys: {sunrise, sunset},
    visibility,
  } = currentWeather;

  const aqiText = {
    1: "좋음",
    2: "양호",
    3: "보통",
    4: "나쁨",
    5: "매우 나쁨",
  };

  return (
      <CardDiv size="large" className={""} isFullscreen={isFullscreen}>
        <Row className={"styles.grid row-gap-3"}>
          <Col xs={12}>
            <h2 className={`styles.title fs-2 ${isFullscreen ? "mb-3" : "mb-0"}`}>오늘의 주요 정보</h2>
          </Col>
          <Col xs={6}>
            <CardDiv size="small" className={""}>
              <Row>
                <Col>
                  <h3 className={"styles.heading fs-3 mb-1"}>대기 오염 지수</h3>
                </Col>
                <Col className={"text-end"}>
                  <span className={`fs-4 badge ${styles.badge} ${styles[`aqi-` + aqi]} text-black`}>{aqiText[aqi]}</span>
                </Col>
              </Row>

              <Row className={"styles.wrapper mt-3 text-center my-auto justify-content-around"}>
                {/*<Col xs={1} className={"px-1 my-auto align-middle"}>
                  <span className={"styles.icon my-auto align-middle"}><MdAir size={40}/></span>
                </Col>*/}
                <Col className={"styles.item px-1 mx-0"}>
                  <p className={"styles.label fs-5 text-secondary"}>미세먼지</p>
                  <p className={"styles.title fs-3"}>{pm2_5.toFixed(2)}</p>
                </Col>
                <Col className={"styles.item px-1 mx-0"}>
                  <p className={"styles.label fs-5 text-secondary"}>이산화황</p>
                  <p className={"styles.title fs-3"}>{so2.toFixed(2)}</p>
                </Col>
                <Col className={"styles.item px-1 mx-0"}>
                  <p className={"styles.label fs-5 text-secondary"}>이산화질소</p>
                  <p className={"styles.title fs-3"}>{no2.toFixed(2)}</p>
                </Col>
                <Col className={"styles.item px-1 mx-0"}>
                  <p className={"styles.label fs-5 text-secondary"}>오존</p>
                  <p className={"styles.title fs-3"}>{o3.toFixed(2)}</p>
                </Col>
              </Row>
            </CardDiv>
          </Col>
          <Col xs={6}>
            <CardDiv size="small" className={`h-100`}>
              <Row>
                <Col>
                  <h3 className={"styles.heading fs-3 mb-1"}>일출 & 일몰</h3>
                </Col>
              </Row>
              <Row className={"styles.list mt-3"}>
                <Col xs={6} className={"styles.item"}>
                  <Row>
                    <Col xs={4} className={" my-auto align-middle"}>
                      <span className={"styles.icon"}><FiSunrise size={50}/></span>
                    </Col>
                    <Col xs={8}>
                      <div>
                        <p className={"styles.label fs-5"}>일출</p>
                        <p className={"styles.title fs-3"}>{getTime(sunrise)}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} className={"styles.item"}>
                  <Row>
                    <Col xs={4} className={" my-auto align-middle"}>
                      <span className={"styles.icon"}><FiSunset size={50}/></span>
                    </Col>
                    <Col xs={8}>
                      <div>
                        <p className={"styles.label fs-5"}>일몰</p>
                        <p className={"styles.title fs-3"}>{getTime(sunset)}</p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardDiv>
          </Col>

          <Col xs={3}>
            <CardDiv size="small" className={""}>
              <h3 className={"styles.heading fs-3"}>습도</h3>
              <Row className={"styles.wrapper"}>
                <Col className={" align-middle my-auto"}>
                  <span className={"styles.icon align-middle my-auto"}><WiHumidity size={45}/></span>
                </Col>
                <Col className={" align-middle my-auto text-end pe-3"}>
                  <p className={"styles.title fs-3 align-middle my-auto"}>{humidity}%</p>
                </Col>
              </Row>
            </CardDiv>
          </Col>
          <Col xs={3}>
            <CardDiv size="small" className={""}>
              <h3 className={"styles.heading fs-3"}>기압</h3>
              <Row className={"styles.wrapper"}>
                <Col xs={2} className={" align-middle my-auto"}>
                  <span className={"styles.icon align-middle my-auto"}><LiaTachometerAltSolid size={45}/></span>
                </Col>
                <Col xs={10} className={" align-middle my-auto text-end pe-3"}>
                  <p className={"styles.title fs-3 align-middle my-auto"}>{pressure}hpa</p>
                </Col>
              </Row>
            </CardDiv>
          </Col>
          <Col xs={3}>
            <CardDiv size="small" className={""}>
              <h3 className={"styles.heading fs-3"}>가시거리</h3>
              <Row className={"styles.wrapper"}>
                <Col xs={2} className={" align-middle my-auto"}>
                  <span className={"styles.icon"}><MdOutlineVisibility size={45}/></span>
                </Col>
                <Col xs={10} className={" align-middle my-auto text-end pe-3"}>
                  <p className={"styles.title fs-3 align-middle my-auto"}>{visibility / 1000}km</p>
                </Col>
              </Row>
            </CardDiv>
          </Col>
          <Col xs={3}>
            <CardDiv size="small" className={""}>
              <h3 className={"styles.heading fs-3"}>체감 온도</h3>
              <Row className={"styles.wrapper"}>
                <Col className={" align-middle my-auto"}>
                  <span className={"styles.icon"}><WiThermometer size={45}/></span>
                </Col>
                <Col className={" align-middle my-auto text-end pe-3"}>
                  <p className={"styles.title fs-3 align-middle my-auto"}>{`${Math.round(feels_like)}°c`}</p>
                </Col>
              </Row>
            </CardDiv>
          </Col>

        </Row>
      </CardDiv>
  );
}