import React, { useContext, useEffect, useRef, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MainLayout from "../components/layout/MainLayout";
import Content from "../components/layout/Content";
import Sidebar from "../components/layout/Sidebar";
import Slide from "../components/layout/FooterContent/Slide";
import menuJSON from "../data/menus.json";
import { renderIcon } from "../hooks/RenderIcon";
import LogoSmSVG from "../assets/common/logo-sm.svg";
import LiveSVG from "../assets/common/live.svg";
import ComAlert from "../components/layout/FooterContent/ComAlert";
import Notice from "../components/layout/FooterContent/Notice";
import messagesList from "../data/dummyMessages.json";
import Null from "../components/layout/FooterContent/Null";
import {
  getCenterInfo,
  getEmergencyBroadcast,
  getJWT,
  getMessage,
  getNotice,
  postCenterInfo,
} from "../api/apiClient";
import MessagePopup from "../components/layout/popup/MessagePopup";
import useQuery from "../hooks/useQuery";
import { useNavigate } from "react-router-dom";
import KeyboardDebugger from "../hooks/KeyboardDebugger";
// import ForceNavigationOnClick from "../hooks/ForceNavigationOnClick";
import * as FingerprintJS from "@fingerprintjs/fingerprintjs";
// import useBroadcastData from "../hooks/useBroadcastData";
// import {BroadcastContext} from "../context/BroadcastContext";
// import {useDispatch, useSelector} from "react-redux";
// import {setBroadcastData} from "../redux/actions";

const Main = () => {
  // 방송 송출에서 전환 클릭 시 해당 컴포넌트 렌더링
  useEffect(() => {
    setInterval(() => {
      const storedData = JSON.parse(localStorage.getItem("broadcastData"));
      if (storedData) {
        console.log("storedData:", storedData);
        if (storedData.action === "streaming") {
          moveStreaming();
          localStorage.removeItem("broadcastData");
        } else if (storedData.action === "iptv") {
          moveIPTV();
          localStorage.removeItem("broadcastData");
        }
      }
    }, 10000);
  }, []);

  /*const [broadcastData, updateBroadcastData] = useBroadcastData();*/
  /*const {broadcastData} = useContext(BroadcastContext);

  useEffect(() => {
    console.log("broadcastData: ", broadcastData);
  }, [broadcastData]);

  const broadcastDataRef = useRef(broadcastData);

  useEffect(() => {
    broadcastDataRef.current = broadcastData; // 상태 업데이트 후 ref에 최신 값 반영
  }, [broadcastData]);

// ref를 통해 최신 값을 추적 가능
  console.log("Latest broadcastData:", broadcastDataRef.current);*/

  const keyboard = KeyboardDebugger();
  // const click = ForceNavigationOnClick();

  const [menus, setMenus] = useState(menuJSON);
  const [activeMenu, setActiveMenu] = useState("home");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [showNotice, setShowNotice] = useState(false);
  const [noticeInfo, setNoticeInfo] = useState({});
  const [noticeCount, setNoticeCount] = useState(0);
  const [latestMessage, setLatestMessage] = useState(null);
  const [showMessagePopup, setShowMessagePopup] = useState(false);
  const [submenus, setSubmenus] = useState([]);
  const [showSubmenus, setShowSubmenus] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const prevActiveMenu = useRef(activeMenu);

  const getDeviceFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    console.log("fingerPrintId:", result.visitorId);
    return result.visitorId;
  };

  const [fingerPrintId, setFingerPrintId] = useState();

  useEffect(() => {
    const fetchFingerprint = async () => {
      const fingerprint = await getDeviceFingerprint();
      setFingerPrintId(fingerprint);
    };

    fetchFingerprint();
  }, []);

  useEffect(() => {
    console.log("fingerPrintId: ", fingerPrintId);
  }, [fingerPrintId]);

  const query = useQuery();

  useEffect(() => {
    const deviceId = query.deviceID;
    console.log("deviceId: ", deviceId);
    setDeviceId(deviceId);
  }, [query]);

  const [deviceId, setDeviceId] = useState(query.deviceID);
  const [centerInfo, setCenterInfo] = useState({});

  const navigate = useNavigate();

  const postCenter = () => {
    const center_code = centerInfo.center_id;
    const settop_sn = deviceId;
    const finger_print_id = fingerPrintId;

    const data = {
      center_code,
      settop_sn,
      finger_print_id,
    };

    console.log("postCenter data: ", data);

    postCenterInfo(data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    if (!deviceId) {
      navigate("/404");
    } else {
      getCenterInfo(deviceId)
        .then((res) => {
          console.log("get center response: ", res.data);
          if (typeof res.data === "object" && res.data !== null) {
            setCenterInfo(res.data);
            // res가 있을 시 로컬스토리지에 저장
            if (res.data.center_id) {
              localStorage.setItem("centerInfo", JSON.stringify(res.data));
            }
          } else {
            console.error("Invalid response: Data is not an object", res.data);
            // navigate("/404");
          }
          setCenterInfo(res.data);
        })
        .catch((err) => {
          console.error(err);
          // res가 없을 시 로컬 스토리지에 저장된 center_id로 다시 요청
          const localCenterInfo = JSON.parse(
            localStorage.getItem("centerInfo")
          );
          if (localCenterInfo && localCenterInfo.center_id) {
            setCenterInfo(localCenterInfo);

            // 로컬 스토리지에 저장된 데이터를 사용할 경우 DB에 업데이트
            // postCenter();
          }
          // 만약 로컬 스토리지에 저장된 center_id가 없을 시 404로 이동
          else {
            navigate(`/404?deviceID=${deviceId}`);
          }
        });
    }
  }, [deviceId]);

  useEffect(() => {
    if (centerInfo.center_id && fingerPrintId) {
      postCenter();
    }
  }, [centerInfo, fingerPrintId]);

  useEffect(() => {
    console.log("centerInfo: " + JSON.stringify(centerInfo));
  }, [centerInfo]);

  const handleMessageSelect = (message) => {
    setSelectedMessage(message);
    setIsFullscreen(true);
  };

  const handleBackToList = () => {
    setSelectedMessage(null);
  };

  useEffect(() => {
    if (!isFullscreen) {
      setActiveMenu(prevActiveMenu.current);
    }
  }, [isFullscreen]);

  const handleFullscreenToggle = () => {
    if (!isFullscreen) {
      prevActiveMenu.current = activeMenu;
    }
    setIsFullscreen((prev) => !prev);
  };

  useEffect(() => {
    prevActiveMenu.current = activeMenu;
  }, [activeMenu]);

  const renderTitle = (activeMenu) => {
    const menu =
      menus.find((m) => m.name === activeMenu) ||
      menus
        .flatMap((m) => m.submenus || [])
        .find((sm) => sm.name === activeMenu);
    if (!menu) return null;

    if (menu.name === "home") {
      return (
        <div className="d-flex align-items-center">
          <img src={LogoSmSVG} alt={""} className="me-3" />
          <span className="title fs-1 my-auto align-middle">
            Smartsilver.Life
          </span>
        </div>
      );
    }

    if (menu.name === "meeting") {
      return (
        <div className="d-flex align-items-center">
          <img
            src={LiveSVG}
            alt={"LiveSVG"}
            className="me-3"
            style={{ width: "48px", height: "24px" }}
          />
          <span className="title fs-1 my-auto align-middle">
            {menu.name_kor}
          </span>
        </div>
      );
    }

    return (
      <div className="d-flex align-items-center">
        <img
          src={renderIcon(activeMenu, menu)}
          alt={menu.name_kor}
          className="me-3"
        />
        <span className="title fs-1 my-auto align-middle">{menu.name_kor}</span>
      </div>
    );
  };

  const handleAlertCancle = () => {
    setShowAlert(false);
  };

  const handleMessageConfirm = () => {
    setShowAlert(false);
    setShowMessagePopup(true);
  };

  const handleNoticeConfirm = () => {
    setShowNotice(false);
  };

  const getNoticeLatest = () => {
    // getNotice(true, centerInfo.center_id)
    // console.log("centerInfo.center_id ", centerInfo?.center_id);
    if (centerInfo && centerInfo?.center_id !== undefined) {
      getNotice(centerInfo?.center_id)
        .then((res) => {
          // console.log(res);
          const notice = JSON.parse(window.localStorage.getItem("notice"));
          if (notice && res.data.id !== notice.id) {
            setShowNotice(true);
            setNoticeInfo(res.data);
            setNoticeCount(1);
            window.localStorage.setItem("notice", JSON.stringify(res.data));
          } else if (!notice) {
            setShowNotice(true);
            setNoticeInfo(res.data);
            setNoticeCount(1);
            window.localStorage.setItem("notice", JSON.stringify(res.data));
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  const getMessageLatest = () => {
    // 경로당 임시 지정
    // getMessage(true, "ICS-001")
    // console.log("centerInfo.center_id ", centerInfo?.center_id);
    if (centerInfo && centerInfo?.center_id !== undefined) {
      getMessage(true, centerInfo?.center_id)
        .then((res) => {
          const message = JSON.parse(window.localStorage.getItem("message"));
          if (message && res.data[0].id !== message.id) {
            setLatestMessage(res.data[0]);
            setShowAlert(true);
            window.localStorage.setItem("message", JSON.stringify(res.data[0]));
          } else if (!message) {
            setLatestMessage(res.data[0]);
            setShowAlert(true);
            window.localStorage.setItem("message", JSON.stringify(res.data[0]));
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    console.log(latestMessage);
  }, [latestMessage]);

  useEffect(() => {
    setInterval(() => {
      getNoticeLatest();
      getMessageLatest();
    }, 10000);
  }, [centerInfo]);

  useEffect(() => {
    if (noticeInfo.repeat_time && noticeCount <= noticeInfo.repeat_time) {
      if (noticeCount === noticeInfo.repeat_time) {
        setTimeout(() => {
          setShowNotice(false);
        }, 15000);
      } else {
        setTimeout(() => {
          setShowNotice(false);
        }, 14900);
        setTimeout(() => {
          setShowNotice(true);
          setNoticeCount(noticeCount + 1);
        }, 15000);
      }
    }
    // }, [noticeCount, noticeInfo]);
  }, []);

  useEffect(() => {}, [showNotice]);

  /*const [token, setToken] = useState("");

  useEffect(() => {
    if (Object.keys(centerInfo).length !== 0) {
      console.log(centerInfo);
      getJWT(centerInfo.name)
        .then((res) => {
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [centerInfo]);*/

  const moveStreaming = () => {
    setActiveMenu("streaming");
    setIsFullscreen(true);
    const streamingMenu = menus.find((m) => m.name === "streaming");
    if (streamingMenu) {
      setSubmenus(streamingMenu.submenus);
      setShowSubmenus(true);
      setSelectedMenu(streamingMenu);
    } else {
      console.error(`Menu with name streaming not found`);
    }
  };

  const moveIPTV = () => {
    setActiveMenu("iptv");
    setIsFullscreen(true);
    const iptvMenu = menus.find((m) => m.name === "iptv");
    if (iptvMenu) {
      setSubmenus(iptvMenu.submenus);
      setShowSubmenus(true);
      setSelectedMenu(iptvMenu);
    } else {
      console.error(`Menu with name iptv not found`);
    }
  };

  const moveHome = () => {
    setActiveMenu("home");
    setIsFullscreen(false);
    // 새로고침 기능
    window.location.href = window.location.href; // 캐시를 비우고 강력 새로고침
  };

  const getEmergency = () => {
    // console.log("getEmergency");
    getEmergencyBroadcast()
      .then((res) => {
        // 해당 response 확인 필요
        moveStreaming();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // useEffect(() => {
  //   setInterval(() => {
  //     getEmergency();
  //   }, 10000);
  // }, []);

  /* 일단 렌더링 후 10초 뒤 강제 전환 */
  /*useEffect(() => {
    setTimeout(() => {
      moveStreaming();
    }, 10000);
  }, []);*/

  const handleToggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  /* 키보드 입력 매핑 */
  useEffect(() => {
    console.log("keyboard: ", keyboard);
    if (keyboard === "k") {
      moveStreaming();
    } else if (keyboard === "l") {
      moveIPTV();
    } else if (keyboard === "h") {
      moveHome();
    } else if (keyboard === "f") {
      handleToggleFullscreen();
    }
  }, [keyboard]);

  return (
    <MainLayout>
      {/*<p>Broadcast Data: {broadcastData ? broadcastData : "No data"}</p>*/}

      <Row className={"main row-gap-3"}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className={"px-5"}
        >
          <div className={"title my-auto align-middle"}>
            {renderTitle(activeMenu)}
          </div>
        </Col>
        {showMessagePopup && latestMessage ? (
          <MessagePopup
            message={latestMessage}
            onClose={() => setShowMessagePopup(false)}
          />
        ) : (
          <>
            <Col xs={12} className={"px-5"}>
              <Row className={"justify-content-between"}>
                <Col
                  className={`${isFullscreen ? "" : ""}`}
                  style={
                    isFullscreen
                      ? { flex: "0 0 auto", width: "100%" }
                      : { flex: "0 0 auto", width: "79.7%" }
                  }
                >
                  <Content
                    activeMenu={activeMenu}
                    isFullscreen={isFullscreen}
                    setIsFullscreen={handleFullscreenToggle}
                    messagesList={messagesList}
                    selectedMessage={selectedMessage}
                    handleMessageSelect={handleMessageSelect}
                    handleBackToList={handleBackToList}
                    // token={token}
                    deviceId={deviceId}
                    centerInfo={centerInfo}
                    fingerPrintId={fingerPrintId}
                  />
                </Col>
                <Col
                  className={isFullscreen ? "d-none" : ""}
                  style={{
                    flex: "0 0 auto",
                    width: isFullscreen ? "0" : "19.5%",
                  }}
                >
                  <Sidebar
                    menus={menus}
                    activeMenu={activeMenu}
                    setActiveMenu={setActiveMenu}
                    isFullscreen={isFullscreen}
                    submenus={submenus}
                    setSubmenus={setSubmenus}
                    showSubmenus={showSubmenus}
                    setShowSubmenus={setShowSubmenus}
                    selectedMenu={selectedMenu}
                    setSelectedMenu={setSelectedMenu}
                  />
                </Col>
              </Row>
            </Col>

            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className={"px-5 w-100 h-100"}
              style={{ position: "relative" }}
            >
              <>
                {!isFullscreen && activeMenu === "home" ? (
                  <Slide />
                ) : (
                  <Null isFullscreen={isFullscreen} />
                )}

                {showAlert && (
                  <ComAlert
                    type={"message"}
                    text={`${latestMessage?.author}님이 보낸 메시지가 도착하였습니다. 확인하시겠습니까?`}
                    onCancle={handleAlertCancle}
                    onConfirm={handleMessageConfirm}
                    isFullscreen={isFullscreen}
                  />
                )}

                {showNotice && (
                  <Notice
                    isFullscreen={isFullscreen}
                    onCancle={handleNoticeConfirm}
                    noticeInfo={noticeInfo}
                  />
                )}
              </>
            </Col>
          </>
        )}
      </Row>
    </MainLayout>
  );
};

export default Main;
