// web-client/src/App.js
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./pages/Main";
import NotFoundPage from "./pages/NotFoundPage";
import Broadcast from "./components/Broadcast/Broadcast";
// import { BroadcastProvider } from "./context/BroadcastContext";
// import { Provider } from 'react-redux';
// import store from './redux/store';

function App() {
  return (
    // <BroadcastProvider>
    // <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/server" element={<Broadcast />} />
          <Route path="/404" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    // </Provider>
    // </BroadcastProvider>
  );
}

export default App;