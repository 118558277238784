import React, {useEffect, useState} from 'react';
import MainLayout from "../components/layout/MainLayout";
import useQuery from "../hooks/useQuery";
import {useNavigate} from "react-router-dom";
import {getCenterInfo, postCenterInfo} from "../api/apiClient";
import * as FingerprintJS from "@fingerprintjs/fingerprintjs";

const NotFoundPage = () => {

  const getDeviceFingerprint = async () => {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    console.log('fingerPrintId:', result);
    return result.visitorId;
  };

  const [fingerPrintId, setFingerPrintId] = useState();

  useEffect(() => {
    const fetchFingerprint = async () => {
      const fingerprint = await getDeviceFingerprint();
      setFingerPrintId(fingerprint);
    };

    fetchFingerprint();
  }, []);

  useEffect(() => {
    console.log("fingerPrintId: ", fingerPrintId);
  }, [fingerPrintId]);


  const query = useQuery();

  const [deviceId, setDeviceId] = useState(query.deviceID);
  const [centerInfo, setCenterInfo] = useState({});

  useEffect(() => {
    const deviceId = query.deviceID;
    console.log("deviceId: ", deviceId);
    setDeviceId(deviceId);
  }, [query]);

  const navigate = useNavigate();

  const getCheckDevice = () => {
    getCenterInfo(deviceId)
      .then((res) => {
        console.log(res.data);
        setCenterInfo(res.data);
        navigate(`/?deviceID=${deviceId}`);
      })
      .catch((err) => {
        console.error(err);

        // 로컬스토리지에 저장된 centerInfo 확인
        const centerInfo = localStorage.getItem("centerInfo");
        console.log("centerInfo: ", centerInfo);

        // 만약 centerInfo가 있으면 기기 정보를 새로 업데이트
        if (centerInfo) {
          setCenterInfo(JSON.parse(centerInfo));
          postCenter();
        }

        // 없으면 수동 등록
        console.log("직접 등록하세요~");
      });
  };

  const postCenter = () => {
    const center_code = centerInfo.center_id;
    const settop_sn = deviceId;
    const finger_print_id = fingerPrintId;

    const data = {
      center_code,
      settop_sn,
      finger_print_id,
    };

    postCenterInfo(data)
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (!deviceId) {
      navigate("/404");
    } else {
      getCheckDevice();
    }
  }, [deviceId]);


  useEffect(() => {
    setInterval(() => {
      //  10초마다 새로고침
      getCheckDevice();
    }, 10000);
  }, []);

  return (
    <MainLayout>
      {/*<h1 className={"mt-5"}>404 - Page Not Found</h1>*/}

      <h1 className={"mt-5 center"}>기기 정보를 확인해 주세요.</h1>
      <h3 className={"mt-5"}>deviceID = {deviceId}</h3>
      <h3 className={"mt-5"}>fingerprintID = {fingerPrintId}</h3>
      <h2 className={"mt-5"}>정보</h2>
      <ul>
        <li>등록되지 않은 기기입니다.</li>
      </ul>
    </MainLayout>
  );
};

export default NotFoundPage;
