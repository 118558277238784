import {useEffect, useState} from 'react';

const useCustomKeyboardNavigation = (onTabChange) => {
  const [focusedElement, setFocusedElement] = useState(null);
  const [focusedDataId, setFocusedDataId] = useState(null);

  useEffect(() => {
    console.log("Focused Element !!!!!: ", focusedElement);
  }, [focusedElement]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'ArrowDown' || e.key === 'ArrowRight') {
        e.preventDefault();
        simulateTabKey(false);
      } else if (e.key === 'ArrowUp' || e.key === 'ArrowLeft') {
        e.preventDefault();
        simulateTabKey(true);
      } else if (e.key === 'Home') {
        e.preventDefault();
        focusFirstElement();
      } else if (e.key === 'Enter' || e.key === 'F4') {
        e.preventDefault();
        simulateEnterKey();
      }
    };

    const simulateTabKey = (reverse = false) => {
      let focusableElements = Array.from(
          document.querySelectorAll(
              'a, button, input, textarea, select, [tabindex]'
          )
      ).filter((element) => element.tabIndex !== -1);

      let newFocusableElements;
      if (!reverse) {
        // Move remaining images to the end of the array
        const imgElements = focusableElements.filter((element) => element.tagName === 'IMG');
        newFocusableElements = focusableElements.filter((element) => element.tagName !== 'IMG');
        newFocusableElements.push(...imgElements);
      } else {
        newFocusableElements = focusableElements;
      }

      console.log("newFocusableElements:", newFocusableElements);

      const currentIndex = newFocusableElements.indexOf(document.activeElement);
      const lastIndex = newFocusableElements.length - 1;
      let nextIndex;

      if (currentIndex === -1) {
        nextIndex = reverse ? lastIndex : 0;
      } else if (reverse) {
        nextIndex = currentIndex === 0 ? lastIndex : currentIndex - 1;
      } else {
        nextIndex = currentIndex === lastIndex ? 0 : currentIndex + 1;
      }

      newFocusableElements[nextIndex]?.focus();
      setFocusedElement(newFocusableElements[nextIndex]);
      setFocusedDataId(newFocusableElements[nextIndex]?.getAttribute('data-id'));
      if (onTabChange) onTabChange(newFocusableElements[nextIndex]);
    };

    const focusFirstElement = () => {
      const focusableElements = Array.from(
          document.querySelectorAll(
              'a, button, input, textarea, select, [tabindex]:not([tabindex="-1"])'
          )
      );

      focusableElements[0]?.focus();
      setFocusedElement(focusableElements[0]);
      setFocusedDataId(focusableElements[0]?.getAttribute('data-id'));
      if (onTabChange) onTabChange(focusableElements[0]);
    };

    const simulateEnterKey = () => {
      const activeElement = document.activeElement;
      if (activeElement) {
        activeElement.click();
      }

      setTimeout(() => {
        const activeElementId = activeElement.getAttribute('data-id');

        if (activeElementId) {
          setFocusedDataId(activeElementId);

          const focusableElements = Array.from(
              document.querySelectorAll(
                  'a, button, input, textarea, select, [tabindex]'
              )
          ).filter((element) => element.tabIndex !== -1);

          const element = focusableElements.find((element) => element.getAttribute('data-id') === activeElementId);

          if (element) {
            element.focus();
            setFocusedElement(element);
            if (onTabChange) onTabChange(element);
          }
        }
      }, 100);
    };

    const observer = new MutationObserver(() => {
      const activeElement = document.activeElement;
      const dataId = activeElement.getAttribute('data-id');
      setFocusedElement(activeElement);
      setFocusedDataId(dataId);
      if (onTabChange) onTabChange(activeElement);
    });

    observer.observe(document, {attributes: true, childList: true, subtree: true});

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      observer.disconnect();
    };
  }, [onTabChange]);

  return focusedElement;
};

export default useCustomKeyboardNavigation;