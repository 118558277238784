import React, {useEffect} from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LogoSVG from "../../assets/common/logo.svg";
import HomeSVG from "../../assets/menus/icon-home.svg";
import {renderIcon} from "../../hooks/RenderIcon";
import Arrow from "../../assets/menus/arrow.svg";

const Sidebar = ({
                   menus,
                   activeMenu,
                   setActiveMenu,
                   isFullscreen,
                   submenus,
                   setSubmenus,
                   showSubmenus,
                   setShowSubmenus,
                   selectedMenu,
                   setSelectedMenu
                 }) => {
  useEffect(() => {
    // console.log(menus);
  }, [menus]);

  useEffect(() => {
    // console.log("submenus : " + JSON.stringify(submenus));
  }, [submenus]);

  useEffect(() => {
    if (activeMenu === "streaming") {
      const broadcastMenu = menus.find((m) => m.name === "broadcast");
      if (broadcastMenu) {
        setSubmenus(broadcastMenu.submenus);
        setShowSubmenus(true);
        setSelectedMenu(broadcastMenu);
      }
    }
  }, [activeMenu, menus, setSubmenus, setShowSubmenus, setSelectedMenu]);

  const handleActiveMenu = (menu) => {
    // console.log(menu);
    if (menu.name === "home") {
      setActiveMenu("home");
      setSubmenus([]);
      setShowSubmenus(false);
      setSelectedMenu(null);
      // window.location.reload(); // Add this line to refresh the page
      window.location.href = window.location.href; // 캐시를 비우고 강력 새로고침
    } else {
      const submenu = menus.find((m) => m.name === menu.name);
      if (submenu) {
        setSubmenus(submenu.submenus);
        setShowSubmenus(true);
        setSelectedMenu(menu);
      } else {
        // console.log(menus);
        // console.error(`Menu with name ${menu.name} not found`);
      }
    }
  };

  const handleSubmenuClick = (submenu) => {
    setActiveMenu(submenu.name);
  };

  const handleKeyDown = (event, item, isSubmenu = false) => {
    if (event.key === "Enter" || event.key === " ") {
      if (isSubmenu) {
        handleSubmenuClick(item);
      } else {
        handleActiveMenu(item);
      }
    }
  };

  const handlePrevBtnClick = () => {
    setShowSubmenus(false);
    setSubmenus([]);
    setSelectedMenu(null);
  };

  return (
    <>
      {!isFullscreen && (
        <div className={`pe-3`}>
          <Row className={"sidebar card border-0 rounded-5 py-4 px-3 gap-2"}>
            <Col
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              xxl={12}
              className={"py-3 px-4"}
            >
              <img src={LogoSVG} alt={"Logo"} className={"img-fluid w-100"}/>
            </Col>
            <hr className={"text-white"}/>
            {!showSubmenus &&
              menus.map((menu, index) => (
                <div
                  key={index}
                  className={
                    "menu py-2 ps-3 pe-0 fs-2 align-middle align-items-center" +
                    (activeMenu === menu.name ? " active" : "")
                  }
                  onClick={() => handleActiveMenu(menu)}
                  onKeyDown={(event) => handleKeyDown(event, menu)}
                  tabIndex={0}
                >
                  <img
                    src={renderIcon(activeMenu, menu)}
                    alt={menu.name_kor}
                    className={"icon me-3 align-middle align-items-center my-auto"}
                  />
                  <span className={"align-middle align-items-center my-auto"}>
                    {menu.name_kor}
                  </span>
                </div>
              ))}
            {showSubmenus && (
              <>
                <div
                  className={
                    "menu py-2 ps-3 pe-0 fs-2 align-middle align-items-center"
                  }
                  onClick={() => handleActiveMenu({name: "home"})}
                  onKeyDown={(event) => handleKeyDown(event, {name: "home"})}
                  tabIndex={0}
                >
                  <img src={HomeSVG} alt={"home"} className={"icon me-3"}/>
                  <span className={"align-middle align-items-center my-auto"}>
                    처음으로
                  </span>
                </div>
                <hr className={"text-white"}/>
                {selectedMenu && (
                  <div
                    className={
                      "menu py-2 ps-3 pe-0 fs-2 align-middle align-items-center" +
                      (activeMenu === selectedMenu.name ? " active" : "")
                    }
                    tabIndex={-1}
                  >
                    <img
                      src={renderIcon(activeMenu, selectedMenu)}
                      alt={selectedMenu.name_kor}
                      className={"icon me-3"}
                    />
                    <span className={"align-middle align-items-center my-auto"}>
                      {selectedMenu.name_kor}
                    </span>
                  </div>
                )}
                <hr className={"text-white"}/>
                {submenus.map((submenu, index) => (
                  <div
                    key={index}
                    className={
                      "submenu py-2 ps-3 pe-0 fs-2 align-middle align-items-center" +
                      (activeMenu === submenu.name ? " active" : "")
                    }
                    onClick={() => handleSubmenuClick(submenu)}
                    onKeyDown={(event) => handleKeyDown(event, submenu, true)}
                    tabIndex={0}
                  >
                    <img
                      src={renderIcon(activeMenu, submenu)}
                      alt={submenu.name_kor}
                      className={"icon me-3"}
                    />
                    <span className={"align-middle align-items-center my-auto"}>
                      {submenu.name_kor}
                    </span>
                  </div>
                ))}
                <div
                  className={
                    "prev mt-3 py-2 ps-3 pe-0 fs-4 align-middle align-items-center"
                  }
                  tabIndex={0}
                  onClick={handlePrevBtnClick}
                  onKeyDown={(event) => {
                    if (event.key === "Enter" || event.key === " ") {
                      handlePrevBtnClick();
                    }
                  }}
                >
                  <img src={Arrow} alt="arr" className={"icon me-3"}/>
                  <span className={"align-middle align-items-center my-auto"}>
                    이전으로
                  </span>
                </div>
              </>
            )}
          </Row>
        </div>
      )}
    </>
  );
};

export default Sidebar;