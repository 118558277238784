import React, {useEffect, useState} from "react";
// import thumbnail from "../../assets/main/thumbnail.png";
import HomeBg from "../../assets/common/home-bg.png";
import LogoSVG from "../../assets/common/logo.svg";
// import Home from '../content/main/Home'; // Adjust the import path as needed
import Conf_screen from "../content/conf/conf_screen";
import Media from "../content/additional/media";
import Capture from "../content/additional/capture";
import Weather from "../content/additional/weather";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Card} from "react-bootstrap";
import ScaleExpandingSVG from "../../assets/broadcast/scale-expanding.svg";
import ScaleReductionSVG from "../../assets/broadcast/scale-reduction.svg";
import Before from "../../assets/broadcast/before.svg";
import BusArrived from "../content/additional/BusArrived";
import MessageList from "../content/message/MessageList";
import VideoCall from "../content/message/VideoCall";
import {getRecordTvs} from "../../api/apiClient";
import Streaming from "../content/conf/Streaming";

const Content = ({
                   activeMenu = "home",
                   isFullscreen,
                   setIsFullscreen,
                   messagesList,
                   selectedMessage,
                   handleMessageSelect,
                   handleBackToList,
                   // token,
                   deviceId,
                   centerInfo,
                   fingerPrintId
                 }) => {

  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    // Set the start time when the component mounts
    setStartTime(Date.now());

    return () => {
      // Calculate the time spent when the component unmounts
      const endTime = Date.now();
      const timeSpent = Math.floor((endTime - startTime) / 1000); // Convert to seconds and remove decimal part

      // Send the time spent to the server
      // console.log("activeMenu: ", activeMenu);
      // console.log("activeMenu & Time spent: ", activeMenu, timeSpent);

      const transferActiveMenu = (activeMenu) => {
        switch (activeMenu) {
          case "streaming":
            return "live_streaming";
          case "meeting":
            return "video_call";
          case "iptv":
            return "iptv";
          case "messageList":
            return "message";
          case "videoCall":
            return "video_call";
          case "weather":
            return "weather";
          case "busArrived":
            return "bus";
          default:
            return "";
        }
      }

      // getRecordTvs(deviceId, transferActiveMenu(activeMenu), timeSpent, "")
      // getRecordTvs("SC-001", transferActiveMenu(activeMenu), timeSpent, "")
      getRecordTvs(centerInfo.center_id, transferActiveMenu(activeMenu), timeSpent, "")
        .then((res) => {
          console.log("getRecordTvs res : ", res.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
  }, [activeMenu]);

  useEffect(() => {
    console.log("Content set activeMenu: " + activeMenu);
  }, [activeMenu]);

/*  useEffect(() => {
    console.log("token: ", token);
  }, [token])*/

  const Home = () => {
    return (
      <Row className={"item card border-0 h-100 overflow-hidden"}
           style={{background: "#2c2c2c"}}
      >
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className={"item card border-0 overflow-hidden w-100 h-100"}
          style={{background: "#2c2c2c"}}
        >
          <div
            className={"position-relative w-100 h-100"}
            style={{backgroundImage: `url(${HomeBg})`, backgroundSize: "cover", backgroundPosition: "center"}}
          >
            <div className={"position-absolute top-50 start-50 translate-middle w-25"}>
              <img
                src={LogoSVG}
                alt="logo"
                className={"w-100 text-center"}
              />
              <h3 className={"text-center mt-3"}>
                {/*{centerInfo?.name}*/}
                {Object.keys(centerInfo).length !== 0 ? centerInfo.name : "승인되지 않은 기기입니다."}
              </h3>
            </div>

            <div className={"position-absolute top-0 end-0 text-end"}
                 style={{margin: "10px"}}
            >
              <p>({deviceId})</p>
              <p>[{fingerPrintId}]</p>
              <p>{Object.keys(centerInfo).length !== 0 ? centerInfo?.center_id : ""}</p>
              {/*<p>{centerInfo?.center_id}</p>*/}
              <p>ver 1.1.124</p>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const renderContent = (activeMenu) => {
    if (activeMenu === "home") {
      return <Home/>;
    } else if (activeMenu === "streaming") {
      // console.log("token: ", token);
      // return <Conf_screen token={token}/>;
      return <Streaming centerInfo={centerInfo}/>;
    } else if (activeMenu === "meeting") {
      return <Media/>;
    } else if (activeMenu === "iptv") {
      return <Capture/>;
    } else if (activeMenu === "messageList") {
      return (
        <MessageList
          isFullscreen={isFullscreen}
          selectedMessage={selectedMessage}
          messagesList={messagesList}
          handleMessageSelect={handleMessageSelect}
          centerInfo={centerInfo}
        />
      );
    } else if (activeMenu === "videoCall") {
      // console.log("token: ", token);
      return <VideoCall
        isFullscreen={isFullscreen}
        centerInfo={centerInfo}
        // token={token}
      />;
    } else if (activeMenu === "weather") {
      return <Weather isFullscreen={isFullscreen}/>;
    } else if (activeMenu === "busArrived") {
      return <BusArrived
        isFullscreen={isFullscreen}
        centerInfo={centerInfo}
      />;
    }
  };

  return (
    <div
      className={`content position-relative ${
        isFullscreen ? "fullscreen" : ""
      }`}
    >
      <Row className={"row-gap-2 h-100"}>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          xxl={12}
          className={"h-100"}
        >
          <Card className={"display border-0 rounded-5 h-100 overflow-hidden"}>
            {renderContent(activeMenu)}
            {selectedMessage && (
              <div className={"scale"}>
                {/* <button
                  className={
                    "position-absolute return-btn z-3 end-0 bottom-0 cursor-hover btn btn-primary"
                  }
                  tabIndex={0}
                  onClick={() => handleBackToList()}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleBackToList();
                    }
                  }}
                >
                  돌아가기
                </button> */}
                <img
                  src={Before}
                  alt="before"
                  className={`position-absolute return-icon z-3 end-0 bottom-0  cursor-hover ${
                    isFullscreen ? "full" : ""
                  }`}
                  tabIndex={0}
                  onClick={() => handleBackToList()}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      handleBackToList();
                    }
                  }}
                />
              </div>
            )}

            <div
              className={"scale"}
              // tabIndex={0}
              // onClick={() => {
              //   if (selectedMessage) {
              //     // 메시지 상세 화면에서는 목록으로 돌아가기
              //     // handleBackToList();
              //   } else {
              //     // 다른 경우에는 풀스크린 상태만 토글
              //     setIsFullscreen(!isFullscreen);
              //   }
              // }}
            >
              <img
                tabIndex={0}
                src={isFullscreen ? ScaleReductionSVG : ScaleExpandingSVG}
                onClick={() => setIsFullscreen(!isFullscreen)}
                /*onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    setIsFullscreen(!isFullscreen);
                  }
                }}*/
                alt={"scale"}
                className={
                  "position-absolute scale-icon z-3 end-0 bottom-0 cursor-hover"
                }
                // style={{width: "100px", height: "100px", opacity: "0.5"}}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Content;
