import React from "react";
import BusInfoCard from "./bus/BusInfoCard";
import { Col, Row } from "react-bootstrap";
// import BusApi from "../../../api/BusApi";
import busArrivalInfo from "../../../data/dummyBusArrival.json";
import {
  getBusArrival,
  getBusStatus,
  getWeatherStatus,
} from "../../../api/apiClient";
import { useEffect } from "react";
import { useState } from "react";

const BusArrived = ({ centerInfo }) => {
  const [isOpen, setIsOpen] = useState(false);

  const getBus = () => {
    getBusStatus()
      .then((res) => {
        console.log("getBus", res.data);
        setIsOpen(res.data.bus);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getBus();
  }, []);

  const [busInfo, setBusInfo] = useState(null);

  const getBusArrived = () => {
    // getBusArrival("ICS-002")
    getBusArrival(centerInfo.center_id)
      .then((res) => {
        console.log("bus", res.data);
        setBusInfo(res.data);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    getBusArrived();

    const interval = setInterval(() => {
      getBusArrived();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {isOpen ? (
        <Row className={"row-gap-2 p-3 h-100"}>
          {/* {busArrivalInfo.map((busInfo, idx) => (
        <BusInfoCard key={idx} busInfo={busInfo} />
      ))} */}
          <BusInfoCard busInfo={busInfo?.station_1_info} />
          <BusInfoCard busInfo={busInfo?.station_2_info} />
        </Row>
      ) : (
        <>
          <Row className={"text-white p-5 row-gap-3"}>
            <Col xs={12}>
              <h3>버스 정보를 가져올 수 없습니다.</h3>
            </Col>
            <Col xs={12}>
              <h3>관리자에게 문의하세요.</h3>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default BusArrived;
