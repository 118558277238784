import React, {useContext, useEffect, useState} from 'react';
import {Container, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import IconCamera from "../../assets/icons/icon-camera.png";
import IconMicrophone from "../../assets/icons/icon-mic.png";
import IconCameraOff from "../../assets/icons/icon-camera-off.png";
import IconMicrophoneOff from "../../assets/icons/icon-mic-off.png";
import Conf_sender from "../content/conf/conf_sender";
import Webcam from "react-webcam";
import {getJWT} from "../../api/apiClient";
// import useBroadcastData from "../../hooks/useBroadcastData";
// import {BroadcastContext} from "../../context/BroadcastContext";
// import {useDispatch} from "react-redux";
// import {setBroadcastData} from "../../redux/actions";

const Broadcast = () => {
  // const {setBroadcastData} = useContext(BroadcastContext);

  // const dispatch = useDispatch();

  const handleStreamingClick = () => {
    const newData = {action: 'streaming'};
    localStorage.setItem('broadcastData', JSON.stringify(newData));
    // dispatch(setBroadcastData(newData));
  };

  const handleIPTVClick = () => {
    const newData = {action: 'iptv'};
    localStorage.setItem('broadcastData', JSON.stringify(newData));
    // dispatch(setBroadcastData(newData));
  }

  const [token, setToken] = useState("");

  useEffect(() => {
    getJWT("스튜디오")
      .then((res) => {
        setToken(res.data.token.slice(2, -1));
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    console.log("token: ", token);
  }, [token]);


  const [options, setOptions] = useState({
    device: "1",
    camera: true,
    microphone: true,
  });

  useEffect(() => {
    console.log("options: ", options);
  }, [options]);

  const [renderBroadCast, setRenderBroadCast] = useState(false);

  const handleDevice = (e) => {
    setOptions({...options, device: e.target.value});
  };

  const [devices, setDevices] = React.useState([]);


  const handleDevices = React.useCallback(
    mediaDevices => {
      setDevices(mediaDevices.filter(({kind}) => kind === "videoinput"));
      setOptions({...options, device: mediaDevices.filter(({kind}) => kind === "videoinput")[0]?.deviceId || ""});
      console.log(mediaDevices);
    },
    [setDevices]
  );

  React.useEffect(
    () => {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    },
    [handleDevices]
  );


  const handleCamera = () => {
    setOptions({...options, camera: !options.camera});
  };

  const handleMicrophone = () => {
    setOptions({...options, microphone: !options.microphone});
  };

  const handleStart = (e) => {
    // Start broadcasting
    e.preventDefault();
    console.log("Start broadcasting");
    setRenderBroadCast(true);
  };

  const handleStop = (e) => {
    // Stop broadcasting
    e.preventDefault();
    console.log("Stop broadcasting");
    setRenderBroadCast(false);
  }

  return (
    <Container fluid className={"h-100"} style={{background: "#2c2c2c"}}>
      <Row style={{background: "#2c2c2c"}}>
        <Col xs={12} className={"p-5"}>
          <Row className={"row-gap-5"}>
            <Col xs={10} className={"border"} style={{aspectRatio: "16/9", background: "#2c2c2c"}}>
              {renderBroadCast ? (
                <>
                  <Conf_sender token={token}/>
                </>
              ) : (
                <>
                  <div className={"d-flex justify-content-center align-items-center my-5 "}>
                    <h3>방송 송출 준비중입니다.</h3>
                  </div>
                  <Row className={"justify-content-start"}>
                    {devices.map((device, key) => (
                      <Col xs={12} md={6} lg={4} key={key} className={"text-center my-2"}>
                        <Webcam
                          audio={false}
                          videoConstraints={{deviceId: device.deviceId}}
                          style={{width: "100%"}}
                        />
                        <h4>{device.label || `Device ${key + 1}`}</h4>
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Col>
            <Col xs={2}>
              <Form>
                <Row className={"row-gap-3 justify-content-center"}>
                  <Col xs={11}>
                    <h3>장치 선택</h3>
                  </Col>
                  <Col xs={11}>
                    <Form.Select
                      aria-label="Default select example"
                      value={options.device}
                      onChange={handleDevice}
                      size={"lg"}
                    >
                      {devices.map((device, key) => (
                        <option key={key} value={device.deviceId}>{device.label || `Device ${key + 1}`}</option>
                      ))}
                    </Form.Select>
                  </Col>
                </Row>

                <Row className={"row-gap-3 justify-content-center mt-5"}>
                  <Col xs={11}>
                    <h3>권한 선택</h3>
                  </Col>
                  <Col xs={5} className={"text-center"}>
                    <img src={options.camera ? IconCamera : IconCameraOff} alt={"preview"}
                      // className={"w-50 cursor-hover"}
                         className={"w-50"} style={{opacity: 0.3}}
                      // onClick={handleCamera}
                    />
                  </Col>
                  <Col xs={5} className={"text-center"}>
                    <img src={options.microphone ? IconMicrophone : IconMicrophoneOff} alt={"preview"}
                      // className={"w-50 cursor-hover"}
                         className={"w-50"} style={{opacity: 0.3}}
                      // onClick={handleMicrophone}
                    />
                  </Col>
                </Row>

                <Row className={"row-gap-3 justify-content-center mt-5"}>
                  <Col xs={11}>
                    <h3>방송 송출</h3>
                  </Col>
                  <Col xs={11}>
                    <Button
                      className={"btn btn-primary w-100"}
                      onClick={handleStart}
                    >
                      시작
                    </Button>
                  </Col>
                  <Col xs={11}>
                    <Button
                      variant={"secondary"}
                      className={"btn w-100"}
                      onClick={handleStop}
                    >
                      중지
                    </Button>
                  </Col>
                  <Col xs={11}>
                    <Button
                      variant={"danger"}
                      className={"btn w-100"}
                      onClick={handleStreamingClick}
                    >
                      스트리밍 전환
                    </Button>
                  </Col>
                  <Col xs={11}>
                    <Button
                      variant={"danger"}
                      className={"btn w-100"}
                      onClick={handleIPTVClick}
                    >
                      IPTV 전환
                    </Button>
                  </Col>
                </Row>

              </Form>
            </Col>

          </Row>
        </Col>

      </Row>
    </Container>
  );
};

export default Broadcast;
