import React, {useEffect, useState} from "react";

const KeyboardDebugger = () => {
  const [lastKeyPressed, setLastKeyPressed] = useState("");

  useEffect(() => {
    const handleKeyDown = (event) => {
      console.log(`Key pressed: ${event.key}`);
      setLastKeyPressed(event.key);
    };

    const handleClick = (event) => {
      console.log(`Mouse clicked at: (${event.clientX}, ${event.clientY})`);
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("click", handleClick);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("click", handleClick);
    };
  }, []);

  return lastKeyPressed;
};

export default KeyboardDebugger;