import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { JaaSMeeting, JitsiMeeting } from '@jitsi/react-sdk';
import { getJWT } from '../../../api/apiClient';

const handleJaaSIFrameRef = iframeRef => {
    iframeRef.style.border = '0px solid #3d3d3d';
    iframeRef.style.background = '#3d3d3d';
    iframeRef.style.height = '890px';
    //iframeRef.style.marginBottom = '20px';
};



// ISC App ID
var YOUR_APP_ID = "vpaas-magic-cookie-0a2b3317f63846fd92f5d7b0d281aadd"

function Conf_sender({token}) {
    useEffect(() => {
        console.log("token: ", token);
    }, [token])

    return (
        <JaaSMeeting
            appId = { YOUR_APP_ID }
            roomName={"ICSC-인천-실시간방송"}
            jwt = { token }
            configOverwrite = {{
                 startWithAudioMuted: false,
                 disableLocalVideoFlip: true,
                 doNotFlipLocalVideo: true,
                 prejoinPageEnabled: false,
            //     disableModeratorIndicator: true,
            //     startScreenSharing: false,
            //     enableEmailInStats: false,
            //     welcomePage: {
            //         // Whether to disable welcome page. In case it's disabled a random room
            //         // will be joined when no room is specified.
            //         disabled: true,
            //         // If set, landing page will redirect to this URL.
            //         customUrl: ''
            //     },                

            // lobby: {
            //     // If Lobby is enabled, it starts knocking automatically. Replaces `autoKnockLobby`.
            //     autoKnock: false,
            //     // Enables the lobby chat. Replaces `enableLobbyChat`.
            //     enableChat: true,
            //     },                
            }}

            interfaceConfigOverwrite = {{
            //     VIDEO_LAYOUT_FIT: 'nocrop',
            //     MOBILE_APP_PROMO: false,
            //     TILE_VIEW_MAX_COLUMNS: 4           
            }}
           
            // devices
            // devices={}


            //userInfo = {{
            //    displayName: 'INCHOEN_TESTUSER'
            //}}
            
            //spinner = {}
            
            // Update this with the `8x8.vc` or `stage.8x8.vc` version of interest
            // and avoid mixing up different domains and release versions
            // on the same page at the same time, as only the first
            // external api script will be loaded.
            // release = 'release-1234'

            //useStaging={false}
            getIFrameRef={handleJaaSIFrameRef} 
            />
    )
}

Conf_sender.propTypes = {

}

export default Conf_sender
