import React, {useEffect, useState} from 'react';
import {getJWT, getLiveStreaming} from "../../../api/apiClient";
import Media from "../additional/media";
import Conf_screen from "./conf_screen";
import NoStreamingImg from "../../../assets/broadcast/no_streaming.jpg";

const Streaming = ({centerInfo}) => {

  useEffect(() => {
    centerInfo && console.log("centerInfo: ", centerInfo);
  }, [centerInfo]);

  const [token, setToken] = useState("");

  useEffect(() => {
    if (Object.keys(centerInfo).length !== 0) {
      // console.log(centerInfo);
      getJWT(centerInfo.name)
        .then((res) => {
          setToken(res.data.token.slice(2, -1));
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [centerInfo]);

  useEffect(() => {
    console.log("token: ", token);
  }, [token]);


  const [current, setCurrent] = useState({});

  // api call
  const getCurrent = () => {
    // get current streaming info
    getLiveStreaming()
      .then((res) => {
        console.log("Current streaming info: ", res);
        setCurrent(res);
      })
      .catch((error) => {
        console.error("Current streaming info error: ", error);
      });
  }

  useEffect(() => {
    getCurrent();
  }, []);

  useEffect(() => {
    setInterval(() => {
      getCurrent();
    }, 10000);
  }, []);

  useEffect(() => {
    console.log("current: ", current);
  }, [current]);

  return (
    <>
      {/* 방송이 있을 경우 (스트리밍) */}
      {current && current.data && current.data.content_type === "live" && token && (
        <>
          <Conf_screen token={token}/>
        </>
      )}

      {/* 방송이 있을 경우 (VOD) */}
      {current && current.data && current.data.content_type === "vod" && (
        <>
          <Media
            videoUrl={current.data.vod.video}
            startTime={current.data.start_time}
            endTime={current.data.end_time}
          />
        </>
      )}

      {/* 방송이 없을 경우 */}
      {((current && current.data && current.data.message) || current.length === 0) && (
        <>
          {/*<h3 className={"text-white"}>현재 진행중인 방송이 없습니다.</h3>*/}
          <img src={NoStreamingImg} alt="No streaming" style={{width: "100%"}}/>
        </>
      )}
    </>
  );
};

export default Streaming;