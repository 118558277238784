import React, { useRef, useEffect, useState } from "react";
// import styles from "./BusInfoCard.module.css";
import "./BusInfoCard.scss";
import { Col, Row } from "react-bootstrap";
import BusYR from "../../../../assets/bus/bus-YR.svg";
import BusGY from "../../../../assets/bus/bus-GY.svg";

const BusInfoCard = ({ busInfo }) => {
  console.log("busInfo", busInfo);
  // const itemList = busInfo?.bus_info?.ServiceResult?.msgBody?.itemList || [];
  const itemList =
    busInfo?.bus_info?.ServiceResult?.msgBody?.itemList?.sort(
      (a, b) => a.ARRIVALESTIMATETIME - b.ARRIVALESTIMATETIME
    ) || [];

  // 2분전 도착 버스
  const soonArrivalBus = itemList.filter(
    (bus) => bus.ARRIVALESTIMATETIME <= 120
  );
  // const soonArrivalBus = itemList.find(
  //   (bus) => bus.ARRIVALESTIMATETIME <= 120
  // );

  // 버스 곧도착 애니메이션
  const arriveBusRef = useRef(null); // 부모 컨테이너 참조
  const scrollContainerRef = useRef(null); // 스크롤 컨테이너 참조
  const [isScrollable, setIsScrollable] = useState(false);

  // console.log("reffffff111111", arriveBusRef);
  // console.log("reffffff222222", scrollContainerRef);

  useEffect(() => {
    const checkScrollable = () => {
      if (scrollContainerRef.current && arriveBusRef.current) {
        const containerWidth = arriveBusRef.current.offsetWidth - 50;
        // const contentWidth = scrollContainerRef.current.scrollWidth;
        const contentWidth = Array.from(
          scrollContainerRef.current.querySelectorAll(".scroll-content")
        ).reduce((acc, el) => acc + el.offsetWidth, 0); // 항목들의 총 너비 합산

        console.log("Container Width:", containerWidth);
        console.log("Content Width:", contentWidth);

        setIsScrollable(contentWidth > containerWidth);
      }
    };

    // checkScrollable();
    // DOM 렌더링 이후 강제로 실행 보장
    setTimeout(checkScrollable, 0);

    window.addEventListener("resize", checkScrollable);
    return () => window.removeEventListener("resize", checkScrollable);
  }, [itemList]);

  return (
    <Col className={`bus-info text-center d-flex flex-column gap-3`}>
      <Col className={`bus-title`}>
        <h4 className="fs-1 fw-bold">{busInfo?.name}</h4>
      </Col>
      <Col className="bus-arrival d-flex justify-content-between">
        <Col xs={4} className="arrive-bg">
          <h4 className="fs-1 fw-bold">곧 도착</h4>
        </Col>
        <Col
          xs={8}
          className="d-flex justify-content-center align-items-center gap-3 arrive-bus"
          ref={arriveBusRef}
        >
          <div
            className={`scroll-container d-flex align-items-center gap-3 ${
              isScrollable ? "scroll-animate" : ""
            }`}
            ref={scrollContainerRef}
          >
            {soonArrivalBus ? (
              soonArrivalBus?.slice(0, 3).map((soonBus) => (
                <div key={soonBus.BUSID} className="scroll-content">
                  <img className="d-block" src={BusYR} alt="bus-img01" />
                  <span className="fs-1 fw-bold">
                    {/* {soonArrivalBus?.ROUTENO} */}
                    {soonBus?.ROUTENO}
                  </span>
                </div>
              ))
            ) : (
              <Col></Col>
            )}
            {isScrollable &&
              soonArrivalBus.slice(0, 3).map((soonBus) => (
                <div key={soonBus.BUSID} className="scroll-content">
                  <img className="d-block" src={BusYR} alt="bus-img01" />
                  <span className="fs-1 fw-bold">
                    {/* {soonArrivalBus?.ROUTENO} */}
                    {soonBus?.ROUTENO}
                  </span>
                </div>
              ))}
          </div>
        </Col>
      </Col>
      <Col className="bus-list w-100">
        <Col className="bus-list-title fs-3">
          <Row className="mx-3 px-4 py-2">
            <Col xs={4} className="border-end">
              버스 번호
            </Col>
            <Col xs={4} className="border-end">
              남은 시간
            </Col>
            <Col xs={4} className="">
              남은 정거장
            </Col>
          </Row>
        </Col>
        <Col className="bus-list-contents w-100">
          {busInfo?.bus_info?.ServiceResult?.msgBody && itemList ? (
            itemList?.map((bus, idx) => (
              <Row
                key={idx}
                className="px-3 py-1 bus-list-content align-items-center mx-3 border-bottom"
              >
                <Col
                  xs={4}
                  className="px-0 bus-number border-end d-flex justify-content-center align-items-center gap-2"
                >
                  <img
                    className=""
                    src={BusGY}
                    alt="bus-img02"
                    style={{ width: "35px", height: "35px" }}
                  />
                  <span
                    className="fs-1 fw-bold text-start"
                    style={{ width: "12rem" }}
                  >
                    {/*{bus.BUSID}*/}
                    {bus.ROUTENO}
                  </span>
                </Col>
                <Col xs={4} className="border-end">
                  <span className="fs-2 fw-bold">
                    <b className="" style={{ fontSize: "3.5rem" }}>
                      {Math.ceil(bus.ARRIVALESTIMATETIME / 60)}
                    </b>
                    분 후
                  </span>
                </Col>
                <Col xs={4}>
                  <h4 className="fs-1 fw-bold">{bus.REST_STOP_COUNT}정류장</h4>
                </Col>
              </Row>
            ))
          ) : (
            <Col className="text-black fs-5 mt-4">도착 정보가 없습니다.</Col>
          )}
        </Col>
      </Col>
    </Col>
  );
};

export default BusInfoCard;
